import { showToast } from '@/components/toast';
import { Button } from '@/components/ui/button';
import { InputWithLabel } from '@/components/ui/input';
import { getUsername } from '@/utils/tableau';
import { isRequired } from '@/utils/validation';
import { loginToWriteBackExtreme } from '@/writeback/api';
import { useWBEStore } from '@/writeback/store';
import { useEffect, useState } from 'react';

const TableauAuth = ({}) => {
  const { setUserInfo, url, username } = useWBEStore();

  const [usernameValue, setUsernameValue] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchUsername = async () => {
      const result = await getUsername(username);
      setUsernameValue(result);
    };

    fetchUsername();
  }, []);

  async function login() {
    try {
      await loginToWriteBackExtreme(url, usernameValue, password, 'tableau', setUserInfo);
    } catch (error) {
      showToast('' + error, 'error');
      console.log('Error while logging in:', error);
    }
  }

  return (
    <div>
      <div className="mt-5">
        <InputWithLabel
          id="username"
          label="Username"
          value={usernameValue}
          onChange={(e) => setUsernameValue(e.target.value)}
          validationRules={[isRequired('Username is required')]}
        />
      </div>

      <div className="mt-5">
        <InputWithLabel
          id="password"
          label="Password"
          value={password}
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          validationRules={[isRequired('Password is required', 3)]}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              login();
            }
          }}
        />
        <div className="mt-5 flex items-center justify-between">
          <Button onClick={login}>Login</Button>
        </div>
      </div>
    </div>
  );
};

export default TableauAuth;
