import { isMeasure } from '@/grid/mapping/utils';
import {
  Connection,
  Permission,
  Schema,
  UserInfo,
  UserInfoResponse,
  UserPermission,
  WBEColumnMap,
  WBECombinedColumnConfig,
  WBEConfigMap,
  WriteBackExtreme,
} from '@/types/writeback';
import BiMap from '@/utils/BiMap';
import { CellEditingStoppedEvent, ValueSetterParams } from 'ag-grid-community';
import { create } from 'zustand';
import { getAllRows, getAppInfo, refreshToken, setBulkRows } from './api';
import { queryClient } from './queryClient';
import { TabsType } from '.';

// Local store
interface WBEState extends WriteBackExtreme {
  token: string;
  schemas: Schema[];
  activeSchema: Schema | undefined; // TODO: check
  connections: Connection[];
  permissions: Permission[];
  nameMap: BiMap;
  userInfo: UserInfo | null;
  commentPermissions: number[];
  dataPermissions: number[];
  unmergedRows: any[];
  selectedTab: TabsType;

  setUrl: (url: string) => void;
  setUserPermissions: (permissions: UserPermission[]) => void;
  removeToken: () => void;
  setActiveSchema: (schema: Schema) => void;
  setSchemas: (schemas: Schema[]) => void;
  setNewSchema: (
    schemaID: string,
    schemaName: string,
    primaryKeySlug: string,
    primaryKeyColumn: string,
    connectionID: string
  ) => void;
  setNewCommentSchema: (
    schemaID: string,
    schemaName: string,
    primaryKeySlug: string,
    primaryKeyColumn: string,
    connectionID: string
  ) => void;
  setConnections: (connections: Connection[]) => void;
  setPermissions: (permissions: Permission[]) => void;
  setPrimaryColumn: (column: string) => void;
  resetSchema: () => void;
  resetCommentSchema: () => void;

  isValid: () => boolean;
  setInitialSettings: (wbe: WriteBackExtreme) => void;
  initializeWBE: () => Promise<void>;
  refreshToken: (token: string) => Promise<void>;
  getColumns: (columnsConfig: WBEConfigMap, columnsProperties: WBEColumnMap) => WBECombinedColumnConfig[];
  mergeRows: (rows: any[]) => Promise<any[]>;
  valueSetter: (params: ValueSetterParams) => Promise<boolean> | boolean;
  bulkValueSetter: (params: CellEditingStoppedEvent) => Promise<boolean> | boolean;
  writeData: (params: ValueSetterParams) => Promise<boolean>;
  setUsernameWorksheet: (userNameWorksheet: string, userNameColumn: string) => void;
  setUserInfo: (userInfo: UserInfoResponse) => void;
  amIAllowedToDo: (permission: string, type: string) => boolean;
  isCreatedByMe: (user: number) => boolean;
}

export const useWBEStore = create<WBEState>((set, get) => ({
  url: '',
  connectionID: '',
  schemaID: '',
  primaryKeyColumn: '',
  primaryKeySlug: '',
  schemaName: '',
  username: null,
  nameMap: new BiMap(),
  userInfo: null,
  comments: {
    schemaID: '',
    schemaName: '',
    connectionID: '',
    primaryKeySlug: '',
    primaryKeyColumn: '',
    enabled: false,
    showIndicator: true,
    color: {
      resolved: '#2BA7DF',
      unresolved: '#FF0079',
    },
  },
  audit: {
    enabled: true,
    color: {
      created: '#2BA7DF',
      updated: '#3B474F',
      deleted: '#FF0079',
    },
  },
  token: '',
  schemas: [],
  activeSchema: undefined,
  connections: [],
  permissions: [],
  commentPermissions: [],
  dataPermissions: [],
  columnConfig: {},
  columnProperties: {},
  selectedTab: 'Comments',
  unmergedRows: [],
  setUrl: (url: string) => set({ url }),
  setUserPermissions: (permissions: UserPermission[]) => {
    const state = get();

    // get permissions for the two schemas (add data and comments)
    if (state.schemaID) {
      set({ dataPermissions: getPermission(state.schemaID) });
    }
    if (state.comments.schemaID) {
      set({ commentPermissions: getPermission(state.comments.schemaID) });
    }

    function getPermission(id) {
      return permissions.find((p) => p.schema_key === id)?.permissions;
    }
  },
  removeToken: () => {
    set({ token: '' });
    localStorage.removeItem('wbe_token');
  },
  setPrimaryColumn: (column: string) => set({ primaryKeyColumn: column }),
  setUserInfo: (userInfo: UserInfoResponse) => {
    set({ userInfo: userInfo.data, token: userInfo.token });
    localStorage.setItem('wbe_token', userInfo.token);

    const setUserPermissions = get().setUserPermissions;
    setUserPermissions(userInfo.permissions);
  },
  setActiveSchema: (schema: Schema) => set({ activeSchema: schema }),
  setSchemas: (schemas: Schema[]) => set({ schemas }),
  setConnections: (connections: Connection[]) => set({ connections }),
  setPermissions: (permissions: Permission[]) => set({ permissions }),
  setUsernameWorksheet: (userNameWorksheet: string, userNameColumn: string) =>
    set({ username: { datasheet: userNameWorksheet, column: userNameColumn } }),
  setNewSchema: (schemaID, schemaName, primaryKeySlug, primaryKeyColumn, connectionID) =>
    set({
      schemaID,
      schemaName,
      primaryKeySlug,
      primaryKeyColumn,
      connectionID,
      columnProperties: {},
    }),
  setNewCommentSchema: (schemaID, schemaName, primaryKeySlug, primaryKeyColumn, connectionID) =>
    set((state) => ({
      comments: {
        ...state.comments,
        schemaID,
        schemaName,
        primaryKeySlug,
        primaryKeyColumn,
        connectionID,
      },
    })),

  resetSchema: () =>
    set({
      schemaID: '',
      schemaName: '',
      primaryKeyColumn: '',
      primaryKeySlug: '',
      connectionID: '',
    }),
  resetCommentSchema: () =>
    set((state) => ({
      comments: {
        ...state.comments,
        schemaID: '',
        schemaName: '',
        primaryKeySlug: '',
        primaryKeyColumn: '',
        connectionID: '',
      },
    })),

  // WriteBackExtreme Functions

  isValid: () => {
    const state = get();
    return !!state.url && !!state.token && !!state.schemaID && !!state.connectionID;
  },
  initializeWBE: async () => {
    const state = get();
    if (!state.url || !state.token) {
      return;
    }

    try {
      const appInfo = await getAppInfo(state.url, state.token);
      set({
        connections: appInfo.connections,
        schemas: appInfo.schemas,
        permissions: appInfo.permissions,
      });
    } catch (error) {
      console.error('Failed to initialize WBE:', error);
    }
  },
  refreshToken: async (token: string) => {
    const state = get();
    try {
      const result = await refreshToken(state.url, token);

      const setUserInfo = get().setUserInfo;
      setUserInfo(result);
      const initializeWBE = get().initializeWBE;
      await initializeWBE();
    } catch (error) {
      console.error('Failed to refresh token:', error);
      set({ token });
    }
  },
  getColumns: (columnsConfig: WBEConfigMap, columnsProperties: WBEColumnMap) => {
    const state = get();
    if (!state.url || !state.token || !state.schemaID) {
      return [];
    }

    if (state.schemas?.length === 0) {
      return [];
    }
    const activeSchema = state.schemas.find((schema) => schema.key === state.schemaID);
    if (!activeSchema) {
      return [];
    }

    const fields = activeSchema.fields;
    const columns: WBECombinedColumnConfig[] = [];
    for (const field of fields) {
      const technicalName = field.name;
      const fieldName = field.label;
      state.nameMap?.set(fieldName, technicalName);

      const columnProperties = columnsProperties?.[technicalName] ?? null;
      if (!columnProperties || !columnProperties.visible) {
        continue;
      }
      if (!field.primary) {
        if (field.type === 'select') {
          // get the lastest options from WBE
          columnProperties.options = field.options;
        }

        if (columnProperties.origin === 'worksheet') {
          continue;
        }
        let columnConfig = {}; // as WBEColumnProperties;
        Object.keys(columnsConfig).forEach((key) => {
          if (key === field.name) {
            columnConfig = columnsConfig[key];
            columnConfig.index = key;
            columnConfig.field = key;
            const properties = columnsProperties[key];

            if (properties) {
              columnConfig.editable = properties.editable;
              columnConfig.visible = properties.visible;
              columnConfig.origin = properties.origin;
              columnConfig.color = properties.color;
              columnConfig.colorCellBackground = properties.colorCellBackground;
              columnConfig.origin = 'writebackextreme';
            }
          }
        });
        if (!columnConfig) {
          console.error('Column config not found for:', field.label);
          continue;
        }

        const combinedColumnConfig: WBECombinedColumnConfig = {
          ...columnProperties,
          ...columnConfig,
        } as WBECombinedColumnConfig;
        columns.push(combinedColumnConfig);
      }
    }
    return columns;
  },
  mergeRows: async (rows: any[]) => {
    const state = get();
    if (!state.url || !state.token || !state.schemaID) {
      return rows;
    }

    try {
      const wbeRows = await getAllRows(state.url, state.token, state.schemaID);
      if (!wbeRows || !wbeRows.length) {
        return rows;
      }

      const { primaryKeyColumn, primaryKeySlug } = state;

      /** This object is a map between the primary key and value containing all the cells that have changed for this record */
      const wbeRowsMap = new Map();

      for (let wbeRow of wbeRows) {
        let primaryKeyValue = wbeRow[primaryKeySlug];
        delete wbeRow[primaryKeySlug];

        const deletableProperties = ['created_at', 'updated_at', 'user_id', 'relates'];

        for (let prop of deletableProperties) {
          delete wbeRow[prop];
        }

        // change technical names to column names
        for (let technicalName in wbeRow) {
          const columnName = state.nameMap.forwardMap.get(technicalName) ?? technicalName;
          const columnProperties = state.columnProperties[technicalName];
          if (!columnProperties) {
            continue;
          }

          if (columnProperties.origin === 'worksheet' && !columnProperties.shouldMerge) {
            delete wbeRow[technicalName];
            continue;
          }
          wbeRow[columnName] = wbeRow[technicalName];
          if (columnName !== technicalName) {
            delete wbeRow[technicalName];
          }
          if (wbeRow[columnName] === null) {
            delete wbeRow[columnName];
          }
          // check if the value of each cell is null, if so then delete the cell
          if (wbeRow[technicalName] === null) {
            delete wbeRow[technicalName];
          }
        }
        //use the fieldName instead of the technicalName

        wbeRowsMap.set(primaryKeyValue, wbeRow);
      }
      const activeSchema = state.schemas.find((schema) => schema.key === state.schemaID);
      if (!activeSchema) {
        return [];
      }
      // set({ activeSchema });

      const fields = activeSchema.fields.filter((field) => {
        const columnProperties = state.columnProperties[field.name];
        return columnProperties?.visible;
      });
      const mergedRows = rows.map((row) => {
        const primaryKeyValue = row[primaryKeyColumn];
        let wbeRow = {};
        for (let [key, value] of wbeRowsMap) {
          // change to double equals to allow for type number to string comparison
          if (key == primaryKeyValue) {
            wbeRow = value;
          }
        }

        if (!wbeRow) {
          return row;
        }
        fields.forEach((field) => {
          const columnName = state.nameMap.reverseMap.get(field.name) ?? field.name;
          if (wbeRow[columnName] === undefined) {
            //add column to wbeRow if it doesn't exist
            wbeRow[columnName] = row[columnName];
            if (wbeRow[columnName] === undefined) {
              if (field.method === 'boolean') {
                wbeRow[columnName] = false;
              }
            }
          }
        });
        return {
          ...row,
          ...wbeRow,
        };
      });
      set({ unmergedRows: rows });
      return mergedRows;
    } catch (error) {
      console.error('Failed to merge rows:', error);
      return rows;
    }
  },
  setInitialSettings: async (wbe: WriteBackExtreme) => {
    set({
      connectionID: wbe.connectionID,
      schemaID: wbe.schemaID,
      primaryKeyColumn: wbe.primaryKeyColumn,
      primaryKeySlug: wbe.primaryKeySlug,
      schemaName: wbe.schemaName,
      username: wbe.username,
      comments: wbe.comments,
      audit: wbe.audit,
      url: wbe.url,
      columnConfig: wbe.columnConfig,
    });

    const localToken = localStorage.getItem('wbe_token');
    if (localToken) {
      const refreshToken = get().refreshToken;
      await refreshToken(localToken);
    }

    const amIAllowedToDo = get().amIAllowedToDo;

    if (!amIAllowedToDo('write', 'data')) {
      Object.keys(wbe.columnProperties).forEach((key) => {
        wbe.columnProperties[key].editable = false;
      });
    }
    set({
      columnProperties: wbe.columnProperties,
    });
  },

  valueSetter: (params: ValueSetterParams) => {
    if (params.api.getSelectedNodes()?.length > 1) {
      params.data[params.column.getColId()] = params.newValue;
      return true;
    }
    const { writeData } = get();
    return writeData(params);
  },
  bulkValueSetter: (params: CellEditingStoppedEvent) => {
    if (params.api.getSelectedNodes()?.length === 0) {
      params.node.data[params.column.getColId()] = params.newValue;
      return true;
    }
    const { writeData } = get();
    return writeData(params);
  },
  writeData: async (params: ValueSetterParams) => {
    const { oldValue, column, data, api } = params;
    let { newValue } = params;
    const state = get();

    if (!state.url || !state.token || !state.schemaID || !state.primaryKeySlug || !state.primaryKeyColumn) {
      return false;
    }

    // check if the column is a number
    if (isMeasure(column.getColDef()) || column.getColDef().cellDataType === 'number') {
      newValue = parseFloat(newValue);

      if (isNaN(newValue)) {
        return false;
      }
      // check if the number is too large for a 32-bit signed integer
      if (newValue > 2147483647) {
        return false;
      }
    }
    // return if the value is the
    const oldData = oldValue; // ?? data[column.getColId()];
    if (newValue === oldData) {
      return false;
    }

    const nameMap = state.nameMap;
    // nameMap.reverseMap.get(column.getColId()) -> Tableau Columns
    // column.getColId() -> WBE Columns
    let technicalName = nameMap.get(column.getColId()) ?? column.getColId();

    if (!technicalName) {
      console.error('Technical name not found while writing data for column:', column.getColId());
      return false;
    }

    const bulkData: {
      [key: string]: any;
    } = [];
    const selectedNodes = api.getSelectedNodes();
    if (!selectedNodes || !selectedNodes.length) {
      // single row edited
      bulkData.push({
        [state.primaryKeySlug]: data[state.primaryKeyColumn],
        [technicalName]: newValue,
      });

      // set the new value in the grid
      data[column.getColId()] = newValue;
    } else {
      // multiple rows edited
      selectedNodes.forEach((node) => {
        const primaryValue = node.data[state.primaryKeyColumn];

        bulkData.push({
          [state.primaryKeySlug]: primaryValue,
          [technicalName]: newValue,
        });

        // set the new value in the grid
        node.data[column.getColId()] = newValue;
        api.refreshCells({
          columns: [column],
          rowNodes: [node],
          force: true,
        });
      });
    }

    try {
      await setBulkRows(state.url, state.token, state.schemaID, bulkData);
      queryClient.invalidateQueries({ queryKey: ['audits'] });
      // document.getElementById('reloadButton')!.style.display = 'block';
    } catch (error) {
      console.error('Failed to write data:', error);
      return false;
    }

    return true;
  },
  isCreatedByMe(user: number): boolean {
    const state = get();
    return state.userInfo?.id == user;
  },
  amIAllowedToDo(permission: string, type: string): boolean {
    const state = get();

    const userPermissions = type === 'comment' ? state.commentPermissions : state.dataPermissions;
    const mappedPermissions = userPermissions.map((p) => {
      const permission = state.permissions.find((per) => per.id == p);
      if (permission) {
        return permission.name && permission.section ? `${permission.name}-${permission.section}` : permission.name;
      }
      return undefined;
    });

    return mappedPermissions.includes(permission);
  },
}));

export const wbeStore = useWBEStore;
