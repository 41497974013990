import { Label } from '@/components/ui/label';
import { XIcon } from 'lucide-react';
import toast from 'react-hot-toast';

export function showToast(title: string, type: string = 'info', message?: string, timer?: number) {
  toast(
    (t) => (
      <CustomToast
        t={t}
        title={title}
        message={message}
        type={type}
      />
    ),
    {
      className: 'mb-5',
      duration: timer ? timer : type == 'error' ? Infinity : 2000,
    }
  );
}

export default function CustomToast({ t, title, message, type = 'info' }) {
  const vizzy = {
    info: '/images/vizzy.png',
    error: '/images/vizzy-question.png',
  };

  return (
    <div className="flex items-center">
      <div className="mr-4">
        <div>
          <img
            style={{ maxWidth: '50px', maxHeight: '40px' }}
            src={vizzy[type] ?? vizzy.info}
            alt="vizzy"
          />
        </div>
      </div>
      <div>
        <div className="flex items-center">
          <Label className="flex-1 font-bold text-primary">{title}</Label>
          <div
            className="ml-4 cursor-pointer"
            onClick={() => toast.dismiss(t.id)}>
            <XIcon className="h-5 w-5 text-gray-600 hover:text-gray-800" />
          </div>
        </div>
        <div>{message && <div className="text-sm text-gray-600">{message}</div>}</div>
      </div>
    </div>
  );
}
