import Combobox from '@/components/combobox';
import { Label } from '@/components/ui/label';
import Password from './password';
import OAuth from './oauth';
import Saml from './saml';
import { useEffect, useState } from 'react';
import { AppSettings, AuthMethodRow } from '@/types/writeback';
import Pincode from './pincode';
import TableauAuth from './tableauAuth';
import { generateRandomString } from '@/utils/functional';
import { setupPolling } from '@/writeback/api';
import { useWBEStore } from '../../../../writeback/store';

const Login = ({
  possibleAuthMethods,
  appSettings,
}: {
  possibleAuthMethods: AuthMethodRow[];
  appSettings: AppSettings | null;
}) => {
  const [selectedMethod, setSelectedMethod] = useState<AuthMethodRow | null>(null);
  const [renderAuthMethod, setRenderAuthMethod] = useState<React.ReactNode>(null);
  const [loading, setLoading] = useState(true);

  const url = useWBEStore((state) => state.url);
  const refreshToken = useWBEStore((state) => state.refreshToken);

  const startAuthentication = (key: string) => {
    const uid = generateRandomString(48);
    window.open(`${url}/api/auth/${key}/login?ref=desktop&uid=${uid}&referer=extension`, '_blank');
    setupPolling(uid, url, (loading) => setLoading(loading), refreshToken);
  };

  useEffect(() => {
    if (selectedMethod === null) {
      return;
    }

    if (selectedMethod.value === 'username_password') {
      setRenderAuthMethod(<Password />);
    } else if (selectedMethod.value === 'oauth') {
      setRenderAuthMethod(
        <OAuth
          oauthMethods={appSettings?.settings.oauth}
          startAuthentication={startAuthentication}
          loading={loading}
          setLoading={setLoading}
        />
      );

      if (appSettings?.settings.oauth?.length == 1) {
        // when there is only one oauth provider, try auto login
        startAuthentication(appSettings.settings.oauth[0].key);
      }
    } else if (selectedMethod.value === 'saml') {
      setRenderAuthMethod(
        <Saml
          samlSettings={appSettings?.settings.saml}
          startAuthentication={startAuthentication}
          loading={loading}
          setLoading={setLoading}
        />
      );
      startAuthentication('saml'); // auto login
    } else if (selectedMethod.value === 'pincode') {
      setRenderAuthMethod(<Pincode />);
    } else if (selectedMethod.value === 'tableau_rest_api') {
      setRenderAuthMethod(<TableauAuth />);
    }
  }, [selectedMethod]);

  return (
    <div>
      {possibleAuthMethods.length > 1 && (
        <div>
          <Label>Choose login method</Label>
          <Combobox
            options={possibleAuthMethods}
            placeholder="Select login option"
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(option) => {
              setSelectedMethod(option);
            }}
            value={selectedMethod}
          />
        </div>
      )}
      {renderAuthMethod}
    </div>
  );
};

export default Login;
