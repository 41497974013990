import {
  Audit,
  Column,
  Comment,
  PostComment,
  PutComment,
  UserInfoResponse,
  WBEColumnType,
  WBEUser,
  WritebackAppResponse,
  WritebackDataResponse,
} from '@/types/writeback';
import { wbeStore } from './store';
import { getErrorFromResponse } from '@/utils/error';

// TODO: Convert all parameters to objects for type safety

export const getAppInfo = async (url: string, token: string) => {
  const appInfoURL = `${url}/api/app`;
  const response = await fetch(appInfoURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || !response.ok || response.status !== 200) {
    throw new Error('Failed to fetch app info');
  }

  const appInfo = (await response.json()) as WritebackAppResponse;
  return appInfo;
};

export const refreshToken = async (url: string, token: string) => {
  const response = await fetch(`${url}/api/auth/refresh`, {
    method: 'POST',
    body: JSON.stringify({ token }),
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return (await response.json()) as UserInfoResponse;
};

export const createSchema = async (
  url: string,
  token: string,
  schemaName: string,
  connectionID: string,
  primaryKeyColumn: string,
  preset?: 'supertables-commenting'
) => {
  const schemaURL = `${url}/api/schema/add-schema`;
  const response = await fetch(schemaURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      label: schemaName,
      primary_key: primaryKeyColumn,
      connection: connectionID,
      preset,
      fields: {
        row_id: {
          options: { primary_column: primaryKeyColumn },
        },
      },
    }),
  });

  await getErrorFromResponse(response);

  const responseData = (await response.json()) as {
    code: 'CREATED';
    status: boolean;
    data: {
      schema_id: string;
      primary_key: string;
    };
  };

  const schema = responseData.data;
  return schema;
};

export const createColumn = async (
  url: string,
  token: string,
  schemaID: string,
  label: string,
  type: WBEColumnType,
  precision: number | undefined,
  options: { [key: string]: string } | undefined,
  show_time: boolean | undefined
) => {
  const columnURL = `${url}/api/schema/${schemaID}/add-column`;
  const response = await fetch(columnURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      label,
      type,
      precision,
      options: options,
      show_time,
    }),
  });

  await getErrorFromResponse(response);

  const responseData = (await response.json()) as {
    code: 'CREATED';
    status: boolean;
    data: Column;
  };

  return responseData.data;
};

export const changeColumn = async (
  url: string,
  token: string,
  schemaID: string,
  columnName: string,
  column: object
) => {
  const columnURL = `${url}/api/schema/${schemaID}/change-column/${columnName}`;
  const response = await fetch(columnURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(column),
  });

  await getErrorFromResponse(response);

  const data = await response.json();
  return data.data;
};

export const deleteColumn = async (url: string, token: string, schemaID: string, columnName: string) => {
  const columnURL = `${url}/api/schema/${schemaID}/remove-column/${columnName}`;
  const response = await fetch(columnURL, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  await getErrorFromResponse(response);

  const data = await response.json();
  return data;
};

export const getAllComments = async (url: string, token: string, schemaID: string) => {
  const commentURL = `${url}/api/${schemaID}`;
  const response = await fetch(commentURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    // if error code is 401
    if (response.status === 401) {
      // removeToken();
      throw new Error('Unauthorized');
    }
    throw new Error('Failed to fetch comments');
  }

  const comments = (await response.json()) as {
    data: Comment[];
  };
  return comments;
};

export const getAllAudits = async (url: string, token: string, schemaID: string) => {
  const auditURL = `${url}/api/history/${schemaID}`;
  const response = await fetch(auditURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch audits');
  }

  const audits = (await response.json()) as Audit[];
  return audits;
};

export const upsertComment = async (
  url: string,
  token: string,
  schemaID: string,
  commentObject: PostComment | PutComment | PostComment[] | PutComment[]
) => {
  const commentURL = `${url}/api/${schemaID}`;
  const response = await fetch(commentURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentObject),
  });

  if (!response.ok) {
    throw new Error('Failed to add comment');
  }

  const data = (await response.json()) as {
    code: 'CREATED' | 'UPDATED';
    status: boolean;
    data: Comment;
  };
  return data.data;
};

export const updateComment = async (url: string, token: string, schemaID: string, commentObject: any) => {
  const commentURL = `${url}/api/${schemaID}/bulk`;
  const response = await fetch(commentURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(commentObject),
  });

  if (!response.ok) {
    console.error('Failed to update comment', response);
    throw new Error('Failed to update comment');
  }

  const data = await response.json();
  return data;
};

export const deleteComment = async (url: string, token: string, schemaID: string, commentID: string) => {
  const commentURL = `${url}/api/${schemaID}/${commentID}`;
  const response = await fetch(commentURL, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete comment');
  }

  const data = await response.json();
  return data;
};

export async function getAllRows(url: string, token: string, schemaID: string) {
  const rowsURL = `${url}/api/${schemaID}`;
  const response = await fetch(rowsURL, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response || !response.ok || response.status !== 200) {
    const { removeToken } = wbeStore.getState();
    removeToken();
    throw new Error('Failed to fetch rows');
  }

  const rows = (await response.json()) as WritebackDataResponse;
  return rows.data;
}

export async function setBulkRows(url: string, token: string, schemaID: string, data: any) {
  const rowsURL = `${url}/api/${schemaID}/bulk`;

  const jsonString = JSON.stringify(data, (key, value) => {
    return value === undefined ? null : value;
  });

  const response = await fetch(rowsURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: jsonString,
  });

  if (!response || !response.ok || response.status !== 200) {
    throw new Error('Failed to set rows');
  }

  const json = await response.json();
  return json;
}

// Authentication

export async function loginToWriteBackExtreme(
  url: string,
  username: string,
  credential: string,
  type: 'password' | 'pincode' | 'tableau' | 'noAuth',
  setUserInfo
) {
  const authUrl = type === 'password' ? `${url}/api/auth/login` : `${url}/api/auth/tableau`;

  try {
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error('Cannot reach WriteBackExtreme')), 5000);
    });

    const credentialType = type === 'pincode' ? 'pincode' : 'password';
    const authRequest = fetch(authUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        ...(type != 'noAuth' ? { [credentialType]: hashPasswd(credential) } : {}),
      }),
    });

    const result = await Promise.race([authRequest, timeoutPromise]);
    const response = result as Response;

    if (!response.ok) {
      const errorBody = await response.json();
      if (type === 'pincode' && errorBody?.reason_phrase) {
        throw new Error('Pincode invalid');
      }

      throw new Error('Could not authenticate with WriteBackExtreme. Statuscode: ' + response.status);
    }

    const authResponse = await response.json();

    if (!authResponse?.status) {
      throw new Error(authResponse.err);
    }

    setUserInfo(authResponse);

    return authResponse;
  } catch (error) {
    const err = error as Error;
    console.log('Something went wrong while logging in:', err);
    throw new Error(err.message);
  }
}

export async function setupPolling(
  uid: string,
  url: string,
  setLoading: (loading: boolean) => void,
  refreshToken: (token: string) => void
) {
  try {
    const response = await fetch(`${url}/api/auth/saml/polling/${uid}`);
    const responseJson = await response.json();

    const data = responseJson.data as {
      token: string;
      user: WBEUser;
    } | null;

    if (!data) {
      setLoading(false);
      return;
    }

    if (data.token) {
      setLoading(false);
      refreshToken(data.token);
    }
  } catch (error) {
    const err = error as Error;
    console.error('Error while polling:', err);
    if (err.message.includes('timeout')) {
      setupPolling(uid, url, setLoading, refreshToken); // Retry polling
    } else {
      setLoading(false);
    }
  }
}

function hashPasswd(password: string) {
  return btoa(btoa(password) + "|Probleem'n? Poar neem'n!");
}
